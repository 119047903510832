header {
    position: fixed;
    top: 0;
    background: #fff;
    width: 100%;
    z-index: 9999;
    .fundo-azul{
        @include mq("phone-wide",max){
            padding-bottom: 5px;
        }
    
    }
}


.logo {
    width: 200px;

    @include mq ("phone-wide",max) {
        width: 150px;
      }

    position: relative;
    &::after {
        content: "";
        position: absolute;
        bottom: -20px;
        left: 0;
        height: 2px;
        width: 30%;
        background: #63c4b8;
    }

    img {
        max-width: 100%;
        height: auto;
    }
}

.btn-destaque {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    background-color: #63c4b8;
    border-radius: 30px 30px 30px 30px !important;
    padding: 10px 30px 10px 30px !important;
    font-family: 'Montserrat';
    font-weight: bold;
    color: white !important;
    width: max-content;
    text-decoration: none;
    flex-direction: row-reverse;
    align-items: center;
    gap: 10px;
    @include mq ("phone-wide",max) {
       margin: 0 auto;
       
    }

    a {
        color: white;
        text-decoration: none;
    }

    &:hover {
        color: white;
        transition: 300ms;
        background-color: #2370ea;
        box-shadow: 0px 0px 20px #2370EA;
    }

}

.btn-simule {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    background-color: #ff8e3b;
    border-radius: 30px 30px 30px 30px;
    padding: 17px 30px 17px 30px;
    font-family: 'Montserrat';
    font-weight: bold;
    color: white;
    width: max-content;
    text-decoration: none;

    @include mq ("phone-wide",max) {
        display: none;
    }

    a {
        color: white;
        text-decoration: none;
    }

    &:hover {
        color: white;
        transition: 300ms;
        background-color: #2370ea;
        box-shadow: 0px 0px 20px #2370EA;
    }

}

.btn-destaque-mobile {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    background-color: #63c4b8;
    border-radius: 30px 30px 30px 30px;
    padding: 17px 30px 17px 30px;
    font-family: 'Montserrat';
    font-weight: bold;
    color: white;
    width: max-content;
    text-decoration: none;
    margin-left: 75px;

    a {
        color: white;
        text-decoration: none;
    }

    @include mq ("phone-wide",max) {
        padding: 8px 30px 8px 30px;
      
    }
    @include mq ("phone-wide",min) {
        display: none;
      
    }
}