/* Icon 1 */

#nav-icon2 {
  width: 30px;
  height: 20px;
  position: relative;
  z-index: 9999;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  display: none;

  @include mq ("phone-wide",max) {
    display: block;
  }
}

/* Icon 2 */

#nav-icon2 {
  

  &.open{


    &.menu{
      top:0;
    
    }    
    span{
     background: #63c4b8 !important;
   }
 }
}

#nav-icon2 {
  float:right;

  span {
    display: block;
    position: absolute;
    height: 5px;
    width: 50%;
    background: #63c4b8;
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
}
#nav-icon2 span:nth-child(even) {
  left: 50%;
  border-radius: 0 3px 3px 0;
}

#nav-icon2 span:nth-child(odd) {
  left:0px;
  border-radius: 3px 0 0 3px;
}

#nav-icon2 span:nth-child(1), #nav-icon2 span:nth-child(2) {
  top: 0px;
}

#nav-icon2 span:nth-child(3), #nav-icon2 span:nth-child(4) {
  top: 11px;
}

#nav-icon2 span:nth-child(5), #nav-icon2 span:nth-child(6) {
  top: 23px;
}

#nav-icon2.open span:nth-child(1),#nav-icon2.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon2.open span:nth-child(2),#nav-icon2.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon2.open span:nth-child(1) {
  left: 1px;
  top: 7px;
}

#nav-icon2.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 7px;
}

#nav-icon2.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(5) {
  left: 0px;
  top: 17px;
}

#nav-icon2.open span:nth-child(6) {
  left: calc(50% - 4px);
  top: 17px;
}