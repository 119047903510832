.card-sonho {
    display: flex;
    flex-direction: column;
    background-image: url("../img/7.jpg");
    background-position: center center;
    background-size: cover;
    border-radius: 20px 20px 20px 20px;
    padding: 150px 35px 150px 35px;
    position: relative;

    @include mq ("phone-wide",max) {
        padding: 20px 20px 20px 20px;
    }

    .overley {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: #212121;
        opacity: 0.6;
        z-index: 0;
        border-radius: 20px 20px 20px 20px;
    }

    .containt {
        position: relative;
        z-index: 2;
        padding-left: 160px;

        @include mq ("phone-wide",max) {
            padding-left: 10px;
        }
    }

    .title-sonho {

        h2 {
            font-family: "Montserrat";
            font-size: 60px;
            font-weight: bold;
            line-height: 70px;
            letter-spacing: -1.95px;
            color: #ff8e3b;
            max-width: 605px;

            @include mq ("phone-wide",max) {
                font-size: 35px;
                line-height: 1.5;
            }
        }
    }

    .frase-sonho {
        font-family: "Montserrat";
        color: #ffffff;
        font-size: 25px;
        font-weight: 700;

        @include mq ("phone-wide",max) {
            font-size: 20px;
            margin-bottom: 35px;
        }
    }

    .btn-destaque-sonho {
        display: flex;
        justify-content: space-around;
        font-size: 16px;
        background-color: #63c4b8;
        border-radius: 30px 30px 30px 30px;
        padding: 17px 20px;
        font-family: 'Montserrat';
        font-weight: bold;
    
        &:hover {
            color: white;
            transition: 300ms;
            background-color: #2370ea;
            box-shadow: 0px 0px 20px #2370EA;
        }
    
        a {
            text-decoration: none;
            color: white;
        }
    }
}
