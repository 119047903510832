@import "modules/mixins";
@import "components/banner";
@import "components/header";
@import "components/nav";
@import "components/como-funciona";
@import "components/transformando-vidas";
@import "components/produtos";
@import "components/muito-melhor";
@import "components/sobre-nos";
@import "components/clientes";
@import "components/uma-grande-equipe";
@import "components/blog";
@import "components/sonho";
@import "components/footer";
@import "components/general";
@import "modules/icon-menu";
@import "components/lightbox";

@import "components/consorcio";
@import "components/blog-interno";
@import "components/contato";



// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";



// Fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Open+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Montserrat:wght@500&family=Open+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap');