// .card-produtos {
//     border-style: none;
//     border-width: 1px 1px 1px 1px;
//     border-radius: 25px 25px 25px 25px;
//     padding: 13% 12% 13% 12%;

//     h2 {
//         font-family: 'Montserrat';
//         font-weight: bold;
//         font-size: 30px;
//         line-height: 1.2em;
//         letter-spacing: -0.9px;
//         margin: 20px 0px 20px 0px;
//     }

//     &.imoveis {

//         .btn-imoveis {
//             font-family: "Montserrat";
//             background-color: #2370ea;
//             color: white;
//             padding: 5px 15px;
//             text-align: center;
//             text-decoration: none;
//             display: inline-block;
//             font-size: 15px;
//             border-radius: 15px;
//         }
        
//         h2 {
//             color: #2370ea;
//         }

//         &:hover {
//             box-shadow: 0px 50px 60px 0px rgba(35.00000000000005, 111.9999999999999, 233.99999999999997, 0.12);
//         }
//     }

//     &.veiculos {

//         .btn-veiculos {
//             font-family: "Montserrat";
//             background-color: #2faf69;
//             color: white;
//             padding: 5px 15px;
//             text-align: center;
//             text-decoration: none;
//             display: inline-block;
//             font-size: 15px;
//             border-radius: 15px;
//         }

//         h2 {
//             color: #2faf69;
//         }
//         &:hover {
//             box-shadow: 0px 50px 60px 0px rgba(47.000000000000014, 175.00000000000006, 105.00000000000004, 0.12);
//         }
//     }

//     &.servicos {

//         .btn-servicos {
//             font-family: "Montserrat";
//             background-color: #ff8e3b;
//             color: white;
//             padding: 5px 15px;
//             text-align: center;
//             text-decoration: none;
//             display: inline-block;
//             font-size: 15px;
//             border-radius: 15px;
//         }

//         h2 {
//             color: #ff8e3b;
//         }
//         &:hover {
//             box-shadow: 0px 50px 60px 0px rgba(255, 141.99999999999994, 59.00000000000004, 0.12);
//         }
//     }
// }

.card-itens-produtos {
    display: flex;
    width: 100% auto;
    text-align: center;
    align-items: center;
    padding: 50px 0px 0px 0px;

    @include mq ("phone-wide",max) {
        display: flex;
        flex-direction: column;
        padding: 25px;
    }

    .card-produtos {
        border-style: none;
        border-width: 1px 1px 1px 1px;
        border-radius: 25px 25px 25px 25px;
        padding: 0% 12% 5% 12%;
    
        h2 {
            font-family: 'Montserrat';
            font-weight: bold;
            font-size: 30px;
            line-height: 1.2em;
            letter-spacing: -0.9px;
            margin: 20px 0px 20px 0px;
        }
    
        &.imoveis {
    
            .btn-imoveis {
                font-family: "Montserrat";
                background-color: #2370ea;
                color: white;
                padding: 5px 15px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 15px;
                border-radius: 15px;
            }
            
            h2 {
                color: #2370ea;
            }
    
            &:hover {
                box-shadow: 0px 50px 60px 0px rgba(35.00000000000005, 111.9999999999999, 233.99999999999997, 0.12);
            }
        }
    
        &.veiculos {
    
            .btn-veiculos {
                font-family: "Montserrat";
                background-color: #2faf69;
                color: white;
                padding: 5px 15px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 15px;
                border-radius: 15px;
            }
    
            h2 {
                color: #2faf69;
            }
            &:hover {
                box-shadow: 0px 50px 60px 0px rgba(47.000000000000014, 175.00000000000006, 105.00000000000004, 0.12);
            }
        }
    
        &.servicos {
    
            .btn-servicos {
                font-family: "Montserrat";
                background-color: #ff8e3b;
                color: white;
                padding: 5px 15px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 15px;
                border-radius: 15px;
            }
    
            h2 {
                color: #ff8e3b;
            }
            &:hover {
                box-shadow: 0px 50px 60px 0px rgba(255, 141.99999999999994, 59.00000000000004, 0.12);
            }
        }

        &.pesados {
    
            .btn-pesados {
                font-family: "Montserrat";
                background-color: #eae323;
                color: white;
                padding: 5px 15px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 15px;
                border-radius: 15px;
            }
            
            h2 {
                color: #eae323;
            }
    
            &:hover {
                box-shadow: 0px 50px 60px 0px rgba(234,227,35, 0.25);
            }
        }

        &.motocicleta {
    
            .btn-motocicleta {
                font-family: "Montserrat";
                background-color: #ea2a23;
                color: white;
                padding: 5px 15px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 15px;
                border-radius: 15px;
            }
            
            h2 {
                color: #ea2a23;
            }
    
            &:hover {
                box-shadow: 0px 50px 60px 0px rgba(234,42,35, 0.12);
            }
        }

        &.investimento {
    
            .btn-investimento {
                font-family: "Montserrat";
                background-color: #63c4b8;
                color: white;
                padding: 5px 15px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 15px;
                border-radius: 15px;
            }
            
            h2 {
                color: #63c4b8;
            }
    
            &:hover {
                box-shadow: 0px 50px 60px 0px rgba(99,196,184, 0.12);
            }
        }
    }
}


.img {
    max-width: 100%;
    height: auto;

    @include mq ("phone-wide",max) {
        display: flex;
        flex-direction: column;
    }
}


.frase-consorcio {
    font-family: 'Montserrat';
    color: #ff8e3b;
    // font-size: 50px;
    font-size: 35px;
    font-weight: 700;
    text-align: center;
    line-height: 1;

    @include mq ("phone-wide",max) {
        font-size: 2em;
        line-height: 1.2;
    }
}

.fundo {
    // background-color: #e4e2e2;
    // padding: 35px;
    padding: 10px;
    flex-direction: column;
}

