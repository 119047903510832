.card-numeros {
    
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        padding: 55px 55px 55px 55px;
        border-style: none;
        border-radius: 25px 25px 25px 25px;
        box-shadow: 13px 11px 54px 0px rgb(0 0 0 / 40%);
        background: #fff;

        @include mq ("phone-wide",max) {
            display: flex;
            flex-direction: column;
            margin-top: 50px;
        }

    h2 {
        font-family: 'Montserrat';
        font-weight: 900;
        font-size: 30px;
        line-height: 1.2em;
        letter-spacing: -0.9px;
        margin: 20px 0px 20px 0px;
        text-align: left;
    }

    .number {
        width: 100%;

        &.vida {

            &:hover {
                color: blue;
            }
        }

        &.clientes {

            &:hover {
                color: #149654;
            }
        }

        &.youtube {

            &:hover {
                color: #ff7f31;
            }
        }
    }

    .txt-cards {
        font-family: 'Montserrat';
        font-size: 16px;
        font-weight: 700;
        line-height: 1.2em;
        text-align: left;
        width: 100%;
    }

    h2 {
        color: black;
    }
}

.title-youtube {

    font-family: 'Montserrat', sans-serif;
    color: #f61c0d;
    padding: 0px 25px 0px 100px;
    text-align: left;

    @include mq ("phone-wide",max) {
        padding: 0px;
    }

    h5 {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 1px;

        @include mq ("phone-wide",max) {
            margin-top: 20px;
            text-align: left;
        }
    }
}

.txt-sub {
    font-family: 'Montserrat', sans-serif;
    color: #000;
    text-align: left;
    padding: 0px 25px 0px 100px;

    @include mq ("phone-wide",max) {
        padding: 0px;
    }

    h2 {
        font-size: 35px;
        font-weight: 700;
        line-height: 1.2em;
        letter-spacing: -1.5px;
        padding: 0px;
    }
}

.txt-youtube {
    font-family: 'Montserrat', sans-serif;
    color: #000;
    text-align: justify;
    padding: 0px 25px 0px 100px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5em;

    @include mq ("phone-wide",max) {
        padding: 0px;
    }
}

.btn-ver-canal {
    display: flex;
    justify-content: space-around;
    text-align: center;

    a {
        text-decoration: none;
        color: white;
        font-size: 16px;
        background-color: #63c4b8;
        border-radius: 30px 30px 30px 30px;
        padding: 17px 45px 17px 45px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;

        &:hover {
            background-color: #F61C0D;
            transition: 300ms;
            box-shadow: 0px 0px 20px #f61c0d;
        }
    }

}



