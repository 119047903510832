#menu {

    @include mq ("phone-wide",min) {
        border-left: #63c4b8;
        border-style: solid;
        border-width: 0px 0px 0px 2px;
        height: 38px;

    ul {
        display: flex;
        margin: 0;
        list-style: none;
        font-family: 'Montserrat';
        font-weight: 600;
        align-items: center;
        li {
            margin: 0 5px;

            a {
                color: #ff8e3b;
                text-decoration: none;
                padding: 7px 15px;
                border-radius: 30px;
                margin: 0 auto;
                &:hover {
                    color: #63c4b8;
                    transition: 300ms;
                }
            }
        }
    }
    }

    @include mq ("phone-wide",max) {
        font-family: 'Montserrat';
        position: fixed;
        width: 100%;
        height: 100%;
        right: -100%;
        background: #1e293b;
        top: 70px;

        ul {
            margin: 0;
            padding: 30px;

            li {
                list-style: none;
                a {
                    color: #fff;
                    display: block;
                    padding: 10px 20px;
                    text-decoration: none;
                    font-size: 25px;
                    text-align: center;
                }
            }
        }
    }
    
}

