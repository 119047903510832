.automoveis {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: stretch;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 20px;
    border-radius: 4px;
    padding: 20px 0 !important;
    width: 100%;
    border: none;
    margin-bottom: 20px;

    i {
        font-size: 40px;
        display: block;
    }

    &:not(.active) {
        color: rgb(0, 0, 0) !important;
        background: #F5F5F5;
    }

    &.active,
    &:hover {
        background: #63c4b8 !important;
        color: white !important;
        transition: 0.2s all linear;
    }
}

.tabela-precos {
    .header-preco {
        font-family: "Montserrat";
        background: #63c4b8;
        padding: 15px;
        text-align: center;
        border-radius: 4px !important;


        h3 {
            color: #fff;
        }
    }

    .body-preco {
        font-family: "Montserrat";
        text-align: center;
        border-radius: 4px !important;

        .row {
            margin: 0;

            .col {
                padding: 15px;
            }

            &:nth-of-type(odd) {
                background-color: #f5f5f5;
            }
        }

        h3 {
            small {
                display: block;
                font-size: 0.6em;
                font-weight: 300;
            }
        }
    }
}

.nav-item {
    @include mq ("phone-wide",max) {
        width: 50%;
    }
}