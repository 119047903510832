.box-testemunho {
    font-family: 'Montserrat';
    border-style: none;
    border-width: 1px 1px 1px 1px;
    border-radius: 20px 20px 20px 20px;
    padding: 13% 12% 13% 12%;
    background-color: #63C4B81F;

    &:hover {
        background-color: white;
        box-shadow: 0px 10px 40px #00000033;
        transition: 0.2S all linear;
        .bolinha {
            color: white;
            background-color: rgba(9, 9, 248, 0.781);
            transition: 0.2S all linear;
        }
    }

    @include mq ("phone-wide",max) {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
    }


    .conteudo-todo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 76%;
        text-decoration: none;
        text-align: center;
    
    }

    .txt-testemunho {
        color: #081420d4;
        font-size: 16px;
        font-weight: 500;
        line-height: 25px;
        margin-bottom: 50px;
        padding-left: 25px;
    }

    .nome-usuario {
        color: #212121;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: -0.6px;
    }

    .nome-imovel {
        color: #70798b;
        font-size: 16px;
        font-weight: 500;
        img{
            height: 25px;
            margin-right: 10px;
            display: inline-block;
        }
    }

    .img-testemunho img {
        width: 100%;
        height: auto;
        overflow: hidden;
        box-shadow: 0px 5px 25px #00000026;
        border-radius: 10px;
    }
}
.slickTestemunho {
    .slick-prev:before, .slick-next:before{
        color: #63c4b8;
    }
}
.bolinha {
    background-color: #63c4b8;
    box-shadow: 0px 10px 40px #00000033;
    padding: 15px;
    border-radius: 50px;
    width: 70px;
    height: 70px;
    text-align: center;
    margin: -82px 0px 35px 25px;

    i {
        color: white;
        font-size: 30px;
        margin: 6px;
    }
}

.espacamento-lateral {
    padding: 0px 35px 200px 35px;

    @include mq ("phone-wide",max) {
        padding: 0px 35px 100px 35px;
    }

}

.frase-fique-calmo {
    font-family: 'Montserrat';
    color: #212121;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: -1.5px;
    text-align: center;
    margin-top: 20px;
}

.btn-saiba-mais {
    display: flex;
    justify-content: space-around;
    text-align: center;

    a {
        text-decoration: none;
        color: white;
        font-size: 16px;
        background-color: #63c4b8;
        border-radius: 30px 30px 30px 30px;
        padding: 17px 45px 17px 45px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;

        &:hover {
            background: #ff8e3b;
            transition: 300ms;
            box-shadow: 0px 0px 20px #FF8E3B;
        }
    }

}




