.logo-footer {
    width: 200px;
    margin-left: 165px;
    margin-top: 125px;

    @include mq ("phone-wide",max) {
        margin-left: 50px;
        margin-top: 100px;
        margin-bottom: 20px;
    }
}

#menu-footer {

    @include mq ("phone-wide",max) {
        display: none;
    }

    ul {
        display: flex;
        margin: 0;
        list-style: none;
        font-family: 'Montserrat';
        font-weight: 500;
        margin-top: 40px;
        font-size: 0.875em;

        li {
            margin: 0 5px;

            a {
                color: #000;
                text-decoration: none;
                padding: 7px 15px;
                border-radius: 30px;

                &:hover {
                    color: #63c4b8;
                    transition: 300ms;
                }
            }
        }
    }
}

#dados-footer {
    display: flex;
    flex-direction: column;

    ul {
        margin: 0;
        list-style: none;
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 16px;
        padding: 0px;

        li {
            margin: 5px 1px;

            a {
                color: #000;
                text-decoration: none;
                padding: 7px 15px;
                border-radius: 30px;
            }
        }
    }
}

.title-footer {
    font-family: 'Montserrat';
    color: #63c4b8;
    font-weight: 700;
    font-size: 20px;
}

.rodape {
    font-family: 'Montserrat';
    color: #70798b;
    font-size: 16px;
    font-weight: 500;
    text-align: center;

    p {
        margin: 0;
    }
}

#icones-footer {
    ul {
        display: flex;
        list-style: none;
        justify-content: space-evenly;
        margin: 10px 0;
        @include mq ("phone-wide",max) {
            padding-left: 0rem !important;
        }

        li {
            margin: 0 5px;
            color: #000;
            text-decoration: none;
           
            font-size: 25px;

            &:hover {
                color: #63c4b8;
                transition: 300ms;
            }
        }
    }
}