.sobre-nos {
    font-family: 'Montserrat', sans-serif;
    color: #2370ea;

    h5 {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 1px;
    }
}

.container-sobre-nos {
    padding: 145px 35px 0px 35px;
}

.txt-sobre-nos-title {
    font-family: 'Montserrat', sans-serif;
    color: #000;
    text-align: start;

    h2 {
        font-size: 40px;
        font-weight: 700;
        letter-spacing: -1.5px;
        line-height: 1.2em;
        margin-bottom: 12px;
        color: #ff6c00;
    }
}

.txt-sobre-nos {
    font-family: 'Montserrat', sans-serif;
    color: #000;
    text-align: justify;

    p {
        font-size: 16px;
        font-weight: 500;
        line-height: 25px;
    }
}

.img-sobre-nos {
    max-width: 100%;
    height: auto;
    max-height: 70%;
    border-radius: 300px 20px 20px 20px;
}

.title-sobre-nos {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.6px;
    color: #2370ea;
}

.btn-saber-mais {
    font-family: "Montserrat";
    background-color: #2370ea;
    color: #fff;
    padding: 10px 30px;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    border-radius: 15px;
}

