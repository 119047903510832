.lightbox {
    background-color: fade(black, 80%);  
    overflow: scroll;
    position: fixed;
    display: none;
    z-index: 999999;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    
    &-container {
      position: relative;
      max-width: 960px;
      margin: 7% auto;
      display: block;    
      padding: 0 3%;
      height: auto;
      z-index: 10;
      
      // Increasing top margin for mobile
      @media screen and (max-width: 768px) { margin-top: 10%; }
      @media screen and (max-width: 414px) { margin-top: 13%; }
    }
      
    &-content {
        box-shadow: 0 0 100px #63c4b8;
    }
    
    &-close {
      text-transform: uppercase;    
      background: black;
      position: absolute; 
      font-weight: 300;      
      font-size: 12px;
      display: block;
      border: none;
      color: white;
      top: -22px;
      right: 3%;
    }
  }
  
  .video-container {  
      padding-bottom: 56.25%;
      position: relative;  
      padding-top: 30px; 
      overflow: hidden;
      height: 0; 
  }
  
  .video-container iframe,
  .video-container object,
  .video-container embed {
      position: absolute;
      height: 100%;  
      width: 100%;  
      left: 0;  
      top: 0;
  }
  
  /* IGNORE FORM THIS POINT ON */
  
  body {
    background: #efefef;
  }
  
  #container {
    border-radius: 4px;
    max-width: 300px;
    height: auto;
    padding: 50px;
    background: white;
    margin: 100px auto;
  }
  
  #playme {
    background: #007fed;
    text-transform: uppercase;
    font-weight: 300;
    border: none;
    color: white;
    padding: 10px 15px;
    display: inline-block;
    font-size: 14px;
    margin: 0;
  }