.card-destaque {
    display: flex;
    width: 100% auto;
    text-align: center;
    padding: 55px 55px 55px 55px;

    @include mq ("phone-wide",max) {
        display: flex;
        flex-direction: column;
        padding: 0px;
    }
}

.card-1 {
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #63c4b8;
    box-shadow: 8px 12px 60px 0px rgba(0, 0, 0, 0.05);
    margin: 10px 10px 10px 10px;
    background-color: #63C4B81F;
    border-radius: 25px 25px 25px 25px;
    padding: 70px 70px;
    @include mq ("phone-wide",max) {
        padding: 20px;
    }
}

.txt-1-card {
    font-family: 'Montserrat';
    color: #63c4b8;
    font-size: 100px;
    font-weight: 900;
    @include mq ("phone-wide",max) {
        font-size: 70px;
    }
    
}

.txt-2-card {
    font-family: "Montserrat";
    margin: 20px 0px 0px -10px;
    text-align: center;
    color: #212121;
    font-size: 30px;
    font-weight: 600;
    @include mq ("phone-wide",max) {
        font-size: 24px;
    }
}

.frase-1 {
    font-family: 'Montserrat';
    font-size: 35px;
    line-height: 1.2em;
    letter-spacing: -1.5px;
    font-weight: 700;
    color: #ff8e3b;
    text-align: center;

    @include mq ("phone-wide",max) {
        margin-bottom: 30px;
    }
}

.card-itens {
    display: flex;
    width: 100% auto;
    text-align: center;
    align-items: center;
    padding: 50px;

    @include mq ("phone-wide",max) {
        display: flex;
        flex-direction: column;
        padding: 25px;
    }
}

.card-itens-1 {
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #2370ea;
    box-shadow: 8px 12px 60px 0px rgba(0, 0, 0, 0.05);
    margin: 0px 20px 0px 20px;
    border-radius: 25px 25px 25px 25px;
    padding: 13% 12% 13% 12%;

    @include mq ("phone-wide",max) {
        margin-bottom: 20px;
        padding: 25px;
        margin: 10px 0 ;
    }
}

.itens-txt {
    font-family: 'Montserrat';
    font-size: 26px;
    font-weight: 700;
    line-height: 1.2em;
    letter-spacing: -1.25px;
    color: #2370ea;
    margin: 20px 0px 20px 0px;
    
    @media only screen and (min-width:581px) and (max-width:1440px) {
        white-space: nowrap;
    }
}

.txt-itens {
    font-family: 'Montserrat';
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.6em;
    letter-spacing: 0px;
}

.fundo-padrao {
    background-color: #e1dede;
    padding: 50px 35px 50px 35px;
}