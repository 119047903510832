.noticia-3 {
    font-family: "Montserrat";
    display: flex;
    flex-direction: column;
    background-image: url("../img/reuniao.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    padding: 30px;
    border-radius: 20px 20px 20px 20px;

    @include mq ("phone-wide",max) {
        margin-bottom: 50px;
    }

    .noticia-3-data {
        font-size: 16px;
        font-weight: 500;
        text-transform: capitalize;
        color: white;
        margin-top: 150px;
    }

    .txt-1-noticia {
        font-size: 20px;
        color: white;
        font-weight: bold;
    }

    .txt-2-noticia {
        color: white;
        font-size: 16px;
        font-weight: 500;
        line-height: 25px;
    }

    .button {
    
        .btn-ler-mais {
            display: flex;
            justify-content: space-around;
            font-size: 15px;
            background-color: #63c4b8;
            border-radius: 30px 30px 30px 30px;
            border: none;
            padding: 12px;
            font-family: 'Montserrat';
            
            a {
                text-decoration: none;
                color: black;
            }
        }
    }
}

.noticia-2 {
    font-family: "Montserrat";
    border-style: none;
    margin: 0px 20px 0px 20px;
    border-radius: 20px;
    padding: 30px;
    background: #f1f6f9;
    height: 100%;

    &:hover {
        background: white;
        box-shadow: -15px 30px 80px #ABAFC74C;
    }

    .noticia-2-data {
        font-size: 16px;
        font-weight: 500;
        text-transform: capitalize;
        color: #70798b;
    }

    .txt-1-noticia {
        font-size: 20px;
        color: #3d3d3d;
        font-weight: bold;
    }

    .txt-2-noticia {
        color: #70798b;
        font-size: 16px;
        font-weight: 500;
        line-height: 25px;
    }

    .btn-ler-mais {
        display: flex;
        justify-content: space-around;
        font-size: 15px;
        background-color: #63c4b8;
        border-radius: 30px 30px 30px 30px;
        border: none;
        padding: 12px;
        font-family: 'Montserrat';

        a {
            text-decoration: none;
            color: black;
        }
    }
}


.noticia-1 {
    font-family: "Montserrat";
    border-style: none;
    margin: 0px 20px 0px 20px;
    border-radius: 20px;
    padding: 30px;
    background: #f1f6f9;
    height: 100%;

    @include mq ("phone-wide",max) {
        margin-top: 50px;
    }

    &:hover {
        background: white;
        box-shadow: -15px 30px 80px #ABAFC74C;
    }

    .noticia-1-data {
        font-size: 16px;
        font-weight: 500;
        text-transform: capitalize;
        color: #70798b;
    }

    .txt-1-noticia {
        font-size: 20px;
        color: #3d3d3d;
        font-weight: bold;
    }

    .txt-2-noticia {
        color: #70798b;
        font-size: 16px;
        font-weight: 500;
        line-height: 25px;
    }

    .btn-ler-mais {
        display: flex;
        justify-content: space-around;
        font-size: 15px;
        background-color: #63c4b8;
        border-radius: 30px 30px 30px 30px;
        border: none;
        padding: 12px;
        font-family: 'Montserrat';

        a {
            text-decoration: none;
            color: black;
        }
    }
}