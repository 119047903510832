.como-funciona {
    font-family: 'Montserrat', sans-serif;
    color: #ff8e3b;
    text-align: center;

    h5 {
        font-size: 25px;
        font-weight: bold;
        letter-spacing: 1px;
    }
}

.txt-tao-facil {
    font-family: 'Montserrat', sans-serif;
    color: #000;
    text-align: center;

    h2 {
        font-size: 50px;
        font-weight: 700;
        letter-spacing: -1.5px;
        color: #ff6c00;

        @include mq ("phone-wide", max) {
            font-size: 30px;
        }
    }
}

.container-como-funciona {

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-image: url("../img/Path_5693.svg");
    background-size: 63% auto;
    background-repeat: no-repeat;
    background-position: 50% 5px;

    @include mq ("phone-wide", max) {
        background-image: none;
    }

    >div {
        &:nth-child(1) {
            li {
                background: rgb(253 167 7 / 72%);

                &:hover {
                    background: #2370ea;
                    transition: 300ms;
                }
            }
        }

        &:nth-child(2) {
            li {
                background: rgb(253 167 7 / 85%);

                &:hover {
                    background: #2370ea;
                    transition: 300ms;
                }
            }
        }

        &:nth-child(3) {
            li {
                background: #fda707;

                &:hover {
                    background: #2370ea;
                    transition: 300ms;
                }
            }
        }
    }

    ul {
        padding-left: 2rem;

        @include mq ("phone-wide", max) {
            padding-left: 0rem;
        }

    }

    li {
        position: relative;
        list-style: none;
        border-radius: 50%;
        padding: 15px 30px 15px 30px;
        margin: 0 auto;
        box-shadow: -10px 15px 50px 0px rgba(99.00000000000003, 196.0000000000001, 184.0000000000001, 0.26);
        // background-color: #fda707;
        width: 82px;
        height: 82px;


        @include mq ("phone-wide", max) {
            margin-top: 60px;
        }

        &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border-radius: 50%;
            background: transparent;
            box-shadow: 15px -15px 50px 50px #FFFFFF;
        }

        // &:hover {
        //     background: #2370ea;
        //     transition: 300ms;
        // }

        span {
            font-family: 'Nunito', sans-serif;
            color: white;
            font-size: 35px;
            font-weight: bold;
            margin: auto 0;
        }
    }
}

.vazio {
    padding: 0px 35px 105px 35px;

    @include mq ("phone-wide", max) {
        padding: 0px;
        margin-top: 35px;
    }
}

.decisao {

    padding: 55px 0px 0px 0px;

    h3 {
        font-weight: 700;
        letter-spacing: -0.9px;
        text-align: center;
        font-size: 30px;
        color: #ff6c00;
        margin-bottom: 25px;
    }

    p {
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 25px;
        text-align: justify;
        color: #444;
        max-width: 310px;
        margin: 0 auto;
    }
}

.frase-simples {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    text-align: justify;
    color: #444;
    max-width: 310px;
    text-align: center;
}

.btn-destaque-centro {
    display: flex;
    justify-content: space-around;
    text-align: center;
    // animation: pulse 0.5s infinite;

    a {
        text-decoration: none;
        color: white;
        font-size: 18px;
        background-color: #63c4b8;
        border-radius: 30px 30px 30px 30px;
        padding: 25px 45px 25px 45px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;

        &:hover {
            background: #2faf69;
            transition: 300ms;
            box-shadow: 0px 0px 20px #2faf69;
        }
    }

}

.espaco {
    padding: 30px 0px 145px 0px;

    @include mq ("phone-wide", max) {
        padding: 30px 0px 80px 0px;
    }
}