.btn-whats {
    position: fixed;
    background: #18c139;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 30px;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
    text-align: center;

    @include mq ("phone-wide",max) {
        bottom: 65px;
    }

    i {
        float: left;
        margin: 10px 10px 10px 12px;
    }
}

.fundo-azul {
    background: #1e293b;
}

main {
    overflow-x: hidden;
}