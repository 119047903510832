.container-fluid {
    padding: 10px 35px 5px 35px;
}

.txt-1 {
    font-size: 55px;
    font-weight: bold;
    line-height: 100px;
    letter-spacing: -2px;
    color: #fff;
    margin: 100px 0px 0px 60px;
    padding: 50px 0px 0px 0px;
    border-radius: 0px 0px 0px 0px;
    max-width: 1499px;
    position: relative;

    @include mq ("phone-wide",max) {
        font-size: 60px;
        text-align: center;
        margin: 50px 0px 0px 0px;
        line-height: 1.1;
    }
}

.txt-2 {
    font-family: 'DM Sans', sans-serif;
    font-size: 80px;
    font-weight: bold;
    line-height: 1em;
    letter-spacing: -2px;
    word-spacing: 0px;
    color: #fda707;
    background-color: rgb(253 167 7 / 29%);
    margin: 45px 0px 0px 100px;
    padding: 0px 10px 15px 15px;
    max-width: 400px;
    background-image: url("../img/download-2.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @include mq ("phone-wide",max) {
        font-size: 65px;
        padding: 5px 15px 15px 15px;
        margin: 0px 0px 0px 0px;
        margin-bottom: 15px;
    }
}

.txt-3 {
    text-align: justify;
    font-family: 'Raleway', sans-serif;
    color: #fff;
    font-size: 22px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: -0.5px;
    margin: 0px 0px 0px 60px;
    max-width: 420px;
    @include mq('phone-wide',min){
        position: absolute;
        bottom: 25px;
    }

    @include mq ("phone-wide",max) {
        margin: 0px 15px 10px 10px;
    }
}

.img-home-2 {
    max-width: 100%;
    height: auto;
    border-radius: 200px 15px 15px 15px;
    margin-top: 70px;

    @include mq ("phone-wide",max) {
        
    }
}

.btn-video {
    display: flex;
    justify-content: space-around;
    padding: 17px 30px 17px 30px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;

    @include mq ("phone-wide",max) {
        justify-content: space-evenly;
    }

    a {
        text-decoration: none;
        color: #fff;
    }

    i {
        font-size: 18px;
    }
}

.vazio {
    padding: 20px 35px 130px 35px;
}